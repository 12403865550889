export * from './frequentations.service';
import { FrequentationsService } from './frequentations.service';
export * from './images.service';
import { ImagesService } from './images.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './parks.service';
import { ParksService } from './parks.service';
export * from './rides.service';
import { RidesService } from './rides.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './visits.service';
import { VisitsService } from './visits.service';
export const APIS = [FrequentationsService, ImagesService, NewsService, ParksService, RidesService, UsersService, VisitsService];
