import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage';

import {TabsPage} from './pages/tabs/tabs.page';
import {Tools} from './tools';
import {ApiModule} from '../api';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Toast} from '@ionic-native/toast/ngx';
import {AppMinimize} from '@ionic-native/app-minimize/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    TabsPage,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ApiModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    Tools,
    LocalNotifications,
    ScreenOrientation,
    Geolocation,
    Toast,
    AppMinimize
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
