/**
 * Park-our
 * Une api toute jolie pour gerer vos aventures dans vos parcs preferes !
 *
 * The version of the OpenAPI document: 0.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { InlineResponse20011 } from '../model/models';
import { InlineResponse2006 } from '../model/models';
import { Prediction } from '../model/models';
import { Ride } from '../model/models';
import { RidePostBody } from '../model/models';
import { RidePutBody } from '../model/models';
import { RideStatus } from '../model/models';
import { RideVisit } from '../model/models';
import { RideVisitPostBody } from '../model/models';
import { RideVisitPutBody } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class RidesService {

    protected basePath = 'https://apiv2.loopn.fr';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create Ride
     * Return the new ride
     * @param parkId 
     * @param ridePostBody Body parameters for POST Ride.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createRide(parkId: number, ridePostBody: RidePostBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Ride>;
    public createRide(parkId: number, ridePostBody: RidePostBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Ride>>;
    public createRide(parkId: number, ridePostBody: RidePostBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Ride>>;
    public createRide(parkId: number, ridePostBody: RidePostBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling createRide.');
        }
        if (ridePostBody === null || ridePostBody === undefined) {
            throw new Error('Required parameter ridePostBody was null or undefined when calling createRide.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Ride>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides`,
            ridePostBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a RideVisit
     * @param parkId 
     * @param rideId 
     * @param visitId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRideVisit(parkId: number, rideId: number, visitId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2006>;
    public deleteRideVisit(parkId: number, rideId: number, visitId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2006>>;
    public deleteRideVisit(parkId: number, rideId: number, visitId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2006>>;
    public deleteRideVisit(parkId: number, rideId: number, visitId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling deleteRideVisit.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling deleteRideVisit.');
        }
        if (visitId === null || visitId === undefined) {
            throw new Error('Required parameter visitId was null or undefined when calling deleteRideVisit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<InlineResponse2006>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}/visits/${encodeURIComponent(String(visitId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit Ride
     * Return the ride
     * @param parkId 
     * @param rideId 
     * @param ridePutBody Body parameters for PUT Ride.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editRide(parkId: number, rideId: number, ridePutBody: RidePutBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Ride>;
    public editRide(parkId: number, rideId: number, ridePutBody: RidePutBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Ride>>;
    public editRide(parkId: number, rideId: number, ridePutBody: RidePutBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Ride>>;
    public editRide(parkId: number, rideId: number, ridePutBody: RidePutBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling editRide.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling editRide.');
        }
        if (ridePutBody === null || ridePutBody === undefined) {
            throw new Error('Required parameter ridePutBody was null or undefined when calling editRide.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<Ride>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}`,
            ridePutBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Rides of a specific park
     * Return all rides of a park
     * @param parkId 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllRidesByPark(parkId: number, pageSize?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Ride>>;
    public getAllRidesByPark(parkId: number, pageSize?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Ride>>>;
    public getAllRidesByPark(parkId: number, pageSize?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Ride>>>;
    public getAllRidesByPark(parkId: number, pageSize?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling getAllRidesByPark.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Ride>>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/allCats`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get mosts visited Rides
     * Return the user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMostVisitedRides(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<InlineResponse20011>>;
    public getMostVisitedRides(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<InlineResponse20011>>>;
    public getMostVisitedRides(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<InlineResponse20011>>>;
    public getMostVisitedRides(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<InlineResponse20011>>(`${this.configuration.basePath}/mostVisitedRides`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ride\&#39;s waiting times predictions
     * Return a list of Predictions
     * @param parkId 
     * @param rideId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPredictions(parkId: number, rideId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Prediction>>;
    public getPredictions(parkId: number, rideId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Prediction>>>;
    public getPredictions(parkId: number, rideId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Prediction>>>;
    public getPredictions(parkId: number, rideId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling getPredictions.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling getPredictions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Prediction>>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}/predictions`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ride informations
     * Return informations about the ride
     * @param rideId 
     * @param parkId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRideById(rideId: number, parkId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Ride>;
    public getRideById(rideId: number, parkId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Ride>>;
    public getRideById(rideId: number, parkId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Ride>>;
    public getRideById(rideId: number, parkId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling getRideById.');
        }
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling getRideById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Ride>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ride\&#39;s visits
     * Return a list of RideVisit
     * @param parkId 
     * @param rideId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRideVisitByRideId(parkId: number, rideId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RideVisit>>;
    public getRideVisitByRideId(parkId: number, rideId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RideVisit>>>;
    public getRideVisitByRideId(parkId: number, rideId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RideVisit>>>;
    public getRideVisitByRideId(parkId: number, rideId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling getRideVisitByRideId.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling getRideVisitByRideId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<RideVisit>>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}/visits`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Rides of a specific park
     * Return all rides of a park
     * @param parkId 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRidesByPark(parkId: number, pageSize?: number, page?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Ride>>;
    public getRidesByPark(parkId: number, pageSize?: number, page?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Ride>>>;
    public getRidesByPark(parkId: number, pageSize?: number, page?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Ride>>>;
    public getRidesByPark(parkId: number, pageSize?: number, page?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling getRidesByPark.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Ride>>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ride\&#39;s waiting times
     * Return a list of RideStatus
     * @param parkId 
     * @param rideId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWaitingTimes(parkId: number, rideId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RideStatus>>;
    public getWaitingTimes(parkId: number, rideId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RideStatus>>>;
    public getWaitingTimes(parkId: number, rideId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RideStatus>>>;
    public getWaitingTimes(parkId: number, rideId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling getWaitingTimes.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling getWaitingTimes.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<RideStatus>>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}/waitingTimes`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new RideVisit
     * Return the visit
     * @param parkId 
     * @param rideId 
     * @param rideVisitPostBody Body parameters for POST Ride Visit.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRideVisit(parkId: number, rideId: number, rideVisitPostBody: RideVisitPostBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RideVisit>;
    public postRideVisit(parkId: number, rideId: number, rideVisitPostBody: RideVisitPostBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RideVisit>>;
    public postRideVisit(parkId: number, rideId: number, rideVisitPostBody: RideVisitPostBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RideVisit>>;
    public postRideVisit(parkId: number, rideId: number, rideVisitPostBody: RideVisitPostBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling postRideVisit.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling postRideVisit.');
        }
        if (rideVisitPostBody === null || rideVisitPostBody === undefined) {
            throw new Error('Required parameter rideVisitPostBody was null or undefined when calling postRideVisit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<RideVisit>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}/visits`,
            rideVisitPostBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a ride to favorite
     * Return ride data
     * @param rideId 
     * @param favorite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRideFavorite(rideId: number, favorite: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Ride>>;
    public setRideFavorite(rideId: number, favorite: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Ride>>>;
    public setRideFavorite(rideId: number, favorite: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Ride>>>;
    public setRideFavorite(rideId: number, favorite: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling setRideFavorite.');
        }
        if (favorite === null || favorite === undefined) {
            throw new Error('Required parameter favorite was null or undefined when calling setRideFavorite.');
        }

        let headers = this.defaultHeaders;
        if (favorite !== undefined && favorite !== null) {
            headers = headers.set('favorite', String(favorite));
        }

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<Ride>>(`${this.configuration.basePath}/rides/${encodeURIComponent(String(rideId))}/favorite`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Subscribe to ride status notifications
     * Return ride data
     * @param rideId 
     * @param subscribe 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRideSubscribe(rideId: number, subscribe: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Ride>>;
    public setRideSubscribe(rideId: number, subscribe: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Ride>>>;
    public setRideSubscribe(rideId: number, subscribe: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Ride>>>;
    public setRideSubscribe(rideId: number, subscribe: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling setRideSubscribe.');
        }
        if (subscribe === null || subscribe === undefined) {
            throw new Error('Required parameter subscribe was null or undefined when calling setRideSubscribe.');
        }

        let headers = this.defaultHeaders;
        if (subscribe !== undefined && subscribe !== null) {
            headers = headers.set('subscribe', String(subscribe));
        }

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<Ride>>(`${this.configuration.basePath}/rides/${encodeURIComponent(String(rideId))}/subscribe`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a new RideVisit
     * Return the visit
     * @param parkId 
     * @param rideId 
     * @param visitId 
     * @param rideVisitPutBody Body parameters for PUT Ride Visit.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRideVisit(parkId: number, rideId: number, visitId: number, rideVisitPutBody: RideVisitPutBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RideVisit>;
    public updateRideVisit(parkId: number, rideId: number, visitId: number, rideVisitPutBody: RideVisitPutBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RideVisit>>;
    public updateRideVisit(parkId: number, rideId: number, visitId: number, rideVisitPutBody: RideVisitPutBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RideVisit>>;
    public updateRideVisit(parkId: number, rideId: number, visitId: number, rideVisitPutBody: RideVisitPutBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (parkId === null || parkId === undefined) {
            throw new Error('Required parameter parkId was null or undefined when calling updateRideVisit.');
        }
        if (rideId === null || rideId === undefined) {
            throw new Error('Required parameter rideId was null or undefined when calling updateRideVisit.');
        }
        if (visitId === null || visitId === undefined) {
            throw new Error('Required parameter visitId was null or undefined when calling updateRideVisit.');
        }
        if (rideVisitPutBody === null || rideVisitPutBody === undefined) {
            throw new Error('Required parameter rideVisitPutBody was null or undefined when calling updateRideVisit.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (APIKeyHeader) required
        credential = this.configuration.lookupCredential('APIKeyHeader');
        if (credential) {
            headers = headers.set('apiKey', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<RideVisit>(`${this.configuration.basePath}/parks/${encodeURIComponent(String(parkId))}/rides/${encodeURIComponent(String(rideId))}/visits/${encodeURIComponent(String(visitId))}`,
            rideVisitPutBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
