import {Component, OnInit} from '@angular/core';

import {AlertController, NavController, Platform, ToastController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {ILocalNotification} from '@ionic-native/local-notifications';
import {UsersService, VisitsService} from '../api';
import {Toast} from '@ionic-native/toast/ngx';
import {Tools} from './tools';
import {AppMinimize} from '@ionic-native/app-minimize/ngx';
import {Router} from '@angular/router';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public localNotifications: LocalNotifications,
    public alertCtrl: AlertController,
    public visitsService: VisitsService,
    public toast: Toast,
    public toastCtrl: ToastController,
    public tools: Tools,
    public navCtrl: NavController,
    public usersService: UsersService,
    public appMinimize: AppMinimize,
    public router: Router,
    public screenOrientation: ScreenOrientation
  ) {
    this.initializeApp();
  }

  public counter = 0;

  presentToast() {
    this.toastCtrl.create({
        message: this.tools.translate('app.close'),
        duration: 3000,
        position: 'middle'
      }
    ).then((toastData) => {
      console.log(toastData);
      toastData.present();
    });
  }

  notificationHandle(res: ILocalNotification) {
    let tempData = localStorage.getItem('notif-' + res.data.visit);
    let data;
    if (tempData) {
      data = JSON.parse(tempData);
      localStorage.removeItem('notif-' + res.data.visit);
    } else {
      this.toast.showLongBottom(this.tools.translate('rideVisit.toast.error')).subscribe();
      this.router.navigate(['tabs', 'park-list']);
      return;
    }
    let attente = Date.now() - data.date * 1000;
    attente = Math.round(attente / 1000 / 60);
    setApiKey(this.visitsService).updateRideVisit(
      res.data.parkId,
      res.data.rideId,
      data.id,
      {
        date: data.date,
        waitingTime: attente,
        hasRideOn: true,
        fastpass: data.fastpass,
        friends: data.friends,
      }
    ).subscribe(value => {
      this.toast.showLongBottom(this.tools.translate('rideVisit.toast.saved', {attente})).subscribe();
      this.router.navigate(['tabs', 'park-list', 'park', res.data.parkId, 'ride', res.data.rideId]);
    });
  }


  initializeApp() {
    let notificationCallback = (notification) => {
      // do something when a notification is opened
      if (notification.action.type == 'ActionTaken') {
        if (notification.action.actionID == 'addfriend') {
          setApiKey(this.usersService).addFriend({userId: notification.notification.payload.additionalData.friendId})
            .subscribe(() => {
              this.toast.showLongBottom(this.tools.translate('friends.toast.added')).subscribe();
              this.router.navigate(['tabs', 'home', 'friends']);
            });
        } else if (notification.action.actionID == 'ridestatus') {
          this.router.navigate(['tabs', 'park-list', 'park', notification.notification.payload.additionalData.parkId, 'ride', notification.notification.payload.additionalData.rideId]);
        } else {
          this.toast.showLongBottom('Opened').subscribe();
        }
      }
    };
    let iosSettings = {};
    iosSettings['kOSSettingsKeyAutoPrompt'] = true;
    iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;
    this.platform.ready().then(() => {
        if (window['plugins']) {
          window['plugins'].OneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
          window['plugins'].OneSignal.setRequiresUserPrivacyConsent(true);
          window['plugins'].OneSignal.startInit('663a9322-d7a8-4341-bb10-e04f5b1905f5', '300302409650');
          window['plugins'].OneSignal.inFocusDisplaying(window['plugins'].OneSignal.OSInFocusDisplayOption.Notification);
          window['plugins'].OneSignal.iOSSettings(iosSettings);
          window['plugins'].OneSignal.handleNotificationOpened(notificationCallback.bind(this));
          window['plugins'].OneSignal.endInit();
          window['plugins'].OneSignal.provideUserConsent(true);
          window['plugins'].OneSignal.setLocationShared(false);
        }
        console.log('end init');
        if (this.platform.is('cordova')) {
          this.statusBar.styleDefault();
          try {
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
          } catch (e) {
            // ignore console.error(e);
          }
          if (this.platform.is('android')) {
            this.platform.backButton.subscribeWithPriority(0, () => {
              if (this.router.url === '/tabs/home' || this.router.url === '/tabs/park-list' || this.router.url === '/tabs/stats') {
                if (this.counter == 0) {
                  this.counter++;
                  this.presentToast();
                  setTimeout(() => {
                    this.counter = 0;
                  }, 3000);
                } else {
                  this.appMinimize.minimize();
                }
              } else {
                this.navCtrl.pop();
              }
            });
          }
          this.localNotifications.on('click').subscribe(async (res: ILocalNotification) => {
            if (res.data.visit) {
              const alert = await this.alertCtrl.create({
                message: this.tools.translate('rideVisit.alert.ask'),
                buttons: [{
                  text: this.tools.translate('rideVisit.notification.done.button'),
                  role: 'ok',
                  handler: () => {
                    this.notificationHandle(res);
                  }
                }, {
                  text: this.tools.translate('rideVisit.notification.notdone.button'),
                  role: 'cancel',
                  handler: () => {
                    this.localNotifications.schedule({
                      title: this.tools.translate('rideVisit.notification.done.title', {attname: res.data.ridename}),

                      actions: [{
                        id: 'ride-done',
                        title: this.tools.translate('rideVisit.notification.done.button'),
                        launch: true
                      }],
                      data: {
                        visit: res.data.visit,
                        rideId: res.data.rideId,
                        parkId: res.data.parkId,
                        ridename: res.data.ridename
                      }
                    });
                  }
                }]
              });
              alert.present();
            }
          });
          this.localNotifications.on('ride-done').subscribe((res: ILocalNotification) => {
            this.notificationHandle(res);
          });

          // Save the notification id if we're logged in !
          let apiKey = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : null;
          if (apiKey) {
            let notificationEnabled = localStorage.getItem('pushEnable') ? localStorage.getItem('pushEnable') : false;
            if (notificationEnabled) {
              let handleConnect = function(ids) {
                this.usersService.putNotify(apiKey, {enable: true, notificationId: ids.userId}).subscribe();
              };
              window['plugins'].OneSignal.getIds(handleConnect.bind(this));
            }
          }
        }
      }
    ).catch(reason => {
      console.log(reason);
    })
      .finally(() => {
        console.log('finaly...');
      });

  }

  ngOnInit() {
    if (localStorage.getItem('apiKey') != null && localStorage.getItem('apiKey') != undefined && localStorage.getItem('apiKey') != 'coucou' && localStorage.getItem('apiKey') != 'invite') {
      let userDatas = localStorage.getItem('user');
      if (!userDatas || !JSON.parse(userDatas)) {
        this.getUserDatas();
      } else {
        this.router.navigate(['tabs', 'home']);

      }
    } else if (localStorage.getItem('invite')) {
      if (localStorage.getItem('invite') == 'true') {
        this.router.navigate(['tabs', 'park-list']);
      }
    } else {
      this.navCtrl.setDirection('root', false);
      this.router.navigate(['login']);
    }
  }

  getUserDatas() {
    setApiKey(this.usersService).getUser().subscribe(userDatas => {
      localStorage.setItem('user', JSON.stringify(userDatas));

      if (userDatas.email == null || userDatas.email == '') {
        this.navCtrl.setDirection('root', false);
        this.router.navigate(['userUpgrade']);
      } else {
        this.router.navigate(['tabs', 'home']);
      }
      this.splashScreen.hide();
    });
  }
}

export function setApiKey<T>(apiService: T, apiKey: string = localStorage.getItem('apiKey')): T {
  (apiService as any).configuration.credentials.APIKeyHeader = apiKey;
  return apiService;
}
