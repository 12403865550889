import {Image, ImagesService} from '../api';
import {Storage} from '@ionic/storage';

export class Tools {
  constructor() {
  }

  language = null;
  translations = null;

  static getImageDatas(imagesService: ImagesService, storage: Storage, image: Image, callback: (image: Image) => void) {
    if (image === null || image === undefined || image.path === null || image.path === undefined) {
      return;
    }

    if (image.path.indexOf('assets/imgs/') > -1 && image.path.indexOf('static.loopn.fr') == -1) {
      if (image.path != 'assets/imgs/unknown_logo.png' && !Tools.fileExists(image.path)) {
        image.path = 'assets/imgs/unknown_logo.png';
      }
      callback(image);
      return;
    }

    if (image.data == null) {
      storage.get('imgDataV2:' + image.id).then((stored) => {
        if (stored && stored != 'undefined') {
          const storedImage = JSON.parse(stored);
          if (image.path == storedImage.path) {
            image.data = storedImage.data;
          }
        }

        if (image.data == null) {
          Tools.toDataURL(image.path, (data) => {
            storage.remove('imgData:' + image.id);
            storage.set('imgDataV2:' + image.id, JSON.stringify({path: image.path, data}));
            image.data = data;
            callback(image);
          });
        }

        if (image.data != null) {
          callback(image);
          return;
        }
      }).catch(() => {
        if (image.data == null) {
          Tools.toDataURL(image.path, (data) => {
            console.log('save on local storage');
            storage.remove('imgData:' + image.id);
            storage.set('imgDataV2:' + image.id, JSON.stringify({path: image.path, data}));
            image.data = data;
            callback(image);
          });
        }
      });
    }

    if (image.data != null) {
      callback(image);
    }
  }

  static toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const fr = new FileReader();

      fr.onload = function() {
        callback(this.result);
      };
      fr.onerror = (e) => {
        console.log(e);
      };

      fr.readAsDataURL(xhr.response); // async call
    };

    xhr.send();
  }

  static fileExists(url) {
    if (url) {
      const req = new XMLHttpRequest();
      req.open('GET', url, false);
      req.send();
      return req.status == 200;
    } else {
      return false;
    }
  }

  static getLanguage() {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'french');
    }
    return localStorage.getItem('language');
  }

  static getLanguageJson(language) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/locale/' + language + '.json', false);
    req.send();
    return JSON.parse(req.response);
  }

  dateToString(date: number) {
    const dateObj = new Date(date);
    let day, month;
    switch (dateObj.getDay()) {
      case 1:
        day = this.translate('date.day.monday');
        break;
      case 2:
        day = this.translate('date.day.tuesday');
        break;
      case 3:
        day = this.translate('date.day.wednesday');
        break;
      case 4:
        day = this.translate('date.day.thursday');
        break;
      case 5:
        day = this.translate('date.day.friday');
        break;
      case 6:
        day = this.translate('date.day.saturday');
        break;
      case 0:
        day = this.translate('date.day.sunday');
        break;
    }

    switch (dateObj.getMonth()) {
      case 0:
        month = this.translate('date.month.january');
        break;
      case 1:
        month = this.translate('date.month.february');
        break;
      case 2:
        month = this.translate('date.month.march');
        break;
      case 3:
        month = this.translate('date.month.april');
        break;
      case 4:
        month = this.translate('date.month.may');
        break;
      case 5:
        month = this.translate('date.month.june');
        break;
      case 6:
        month = this.translate('date.month.july');
        break;
      case 7:
        month = this.translate('date.month.august');
        break;
      case 8:
        month = this.translate('date.month.september');
        break;
      case 9:
        month = this.translate('date.month.october');
        break;
      case 10:
        month = this.translate('date.month.november');
        break;
      case 11:
        month = this.translate('date.month.december');
        break;
    }

    if (this.language == 'japanese') {
      return month + dateObj.getDate() + '日 (' + day + ')';
    }
    return day + ' ' + dateObj.getDate() + ' ' + month;
  }

  translate(item, params = {}) {
    const language = Tools.getLanguage();
    if (!this.translations || this.language != language) {
      this.translations = Tools.getLanguageJson(language);
      this.language = language;
    }

    try {
      let translation = this.translations[item];
      if (!translation) {
        translation = item;
      }
      for (const key of Object.keys(params)) {
        translation = translation.replace('#' + key, params[key]);
      }
      return translation;
    } catch (e) {
      return item;
    }
  }

  setLanguage(language) {
    localStorage.setItem('language', language);
    this.translations = Tools.getLanguageJson(language);
    return language;
  }
}

// @colorStatusBar
export function colorStatusBar(color?: string, whiteIcon?: boolean) {
  return (target?: Function) => {
    const parentionViewWillEnter = target.prototype.ionViewWillEnter;

    Object.defineProperty(target.prototype, 'ionViewWillEnter', {
      value: function() {
        this.statusBar.backgroundColorByHexString(color);
        if (whiteIcon) {
          this.statusBar.styleLightContent();
        } else {
          this.statusBar.styleBlackTranslucent();
        }
        if (parentionViewWillEnter) {
          return parentionViewWillEnter.apply(this, arguments);
        }
      }
    });
  };
}
