import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {TabsPage} from './pages/tabs/tabs.page';
import {LoginGuard} from './guards/login.guard';

const routes: Routes = [
  {path: '', loadChildren: './pages/default/default.module#DefaultPageModule'},
  {path: 'login', loadChildren: './pages/login/login.module#LoginPageModule'},
  {path: 'login/parameters', loadChildren: './pages/login/parameters/parameters.module#ParametersPageModule'},
  {path: 'tabs', component: TabsPage, children: [
      /* HOME */
      {path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [LoginGuard]},
      {path: 'home/ride/:rideid', loadChildren: './pages/ride/ride.module#RidePageModule'},
      {path: 'home/ride/:rideid/map', loadChildren: './pages/map/map.module#MapPageModule'},
      {path: 'home/ride/:rideid/visit/:visitid', loadChildren: './pages/ride-visit/ride-visit.module#RideVisitPageModule'},
      {path: 'home/ride/:rideid/visit/:visitid/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},
      {path: 'home/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},
      {path: 'home/parameters', loadChildren: './pages/home/parameters/parameters.module#ParametersPageModule'},
      {path: 'home/parameters/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},
      {path: 'home/parameters/change-password', loadChildren: './pages/home/parameters-change-password/parameters-change-password.module#ParametersChangePasswordPageModule'},

      /* PARK LIST */
      {path: 'park-list', loadChildren: './pages/park-list/park-list.module#ParkListPageModule'},
      {path: 'park-list/ride/:rideid', loadChildren: './pages/ride/ride.module#RidePageModule'},
      {path: 'park-list/ride/:rideid/visit/:visitid', loadChildren: './pages/ride-visit/ride-visit.module#RideVisitPageModule'},
      {path: 'park-list/ride/:rideid/visit/:visitid/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},
      {path: 'park-list/park/:parkid', loadChildren: './pages/park/park.module#ParkPageModule'},
      {path: 'park-list/park/:parkid/map', loadChildren: './pages/map/map.module#MapPageModule'},
      {path: 'park-list/park/:parkid/ride/:rideid', loadChildren: './pages/ride/ride.module#RidePageModule'},
      {path: 'park-list/park/:parkid/ride/:rideid/map', loadChildren: './pages/map/map.module#MapPageModule'},
      {path: 'park-list/park/:parkid/ride/:rideid/visit/:visitid', loadChildren: './pages/ride-visit/ride-visit.module#RideVisitPageModule'},
      {path: 'park-list/park/:parkid/ride/:rideid/visit/:visitid/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},
      {path: 'park-list/park/:parkid/ride/:rideid/login', loadChildren: './pages/login/login.module#LoginPageModule'},

      /* NEWS */
      {path: 'news-list', loadChildren: './pages/news-list/news-list.module#NewsListPageModule'},
      {path: 'news-list/article/:articleid', loadChildren: './pages/news-article/news-article.module#NewsArticlePageModule'},

      /* TIMELINE */
      {path: 'stats', loadChildren: './pages/stats/stats.module#StatsPageModule', canActivate: [LoginGuard]},
      {path: 'stats/park/:parkid', loadChildren: './pages/park/park.module#ParkPageModule'},
      {path: 'stats/park/:parkid/map', loadChildren: './pages/map/map.module#MapPageModule'},
      {path: 'stats/park/:parkid/ride/:rideid', loadChildren: './pages/ride/ride.module#RidePageModule'},
      {path: 'stats/park/:parkid/ride/:rideid/map', loadChildren: './pages/map/map.module#MapPageModule'},
      {path: 'stats/park/:parkid/ride/:rideid/visit/:visitid', loadChildren: './pages/ride-visit/ride-visit.module#RideVisitPageModule'},
      {path: 'stats/park/:parkid/ride/:rideid/visit/:visitid/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},
      {path: 'stats/ride/:rideid', loadChildren: './pages/ride/ride.module#RidePageModule'},
      {path: 'stats/ride/:rideid/map', loadChildren: './pages/map/map.module#MapPageModule'},
      {path: 'stats/ride/:rideid/visit/:visitid', loadChildren: './pages/ride-visit/ride-visit.module#RideVisitPageModule'},
      {path: 'stats/ride/:rideid/visit/:visitid/friends', loadChildren: './pages/friends/friends.module#FriendPageModule'},

      {path: '', redirectTo: '/tabs/home', pathMatch: 'full'}
    ]
  },
  {path: 'user-missing-password', loadChildren: './pages/user-missing-password/user-missing-password.module#UserMissingPasswordPageModule'},
  {path: 'user-change-password', loadChildren: './pages/user-change-password/user-change-password.module#UserChangePasswordPageModule'},
  {path: 'user-subscribe', loadChildren: './pages/user-subscribe/user-subscribe.module#UserSubscribePageModule'},
  {path: 'user-upgrade', loadChildren: './pages/user-upgrade/user-upgrade.module#UserUpgradePageModule'},
  {path: '**', loadChildren: './pages/default/default.module#DefaultPageModule'},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
