import {Component} from '@angular/core';
import {Tools} from '../../tools';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {
  translate = null;

  constructor(private tools: Tools) {
    this.translate = tools.translate;
  }

}
